import {defineStore} from 'pinia'
import {NetworkService} from "~/utils/NetworkService";
import type {Grade} from "~/models/grade";

export const gradeStore = defineStore({
    id: 'grade-store',
    persist: true,
    state: () => {
        return {
            grades: <Grade[]>[],
        }
    },
    actions: {
        clearGradeStore: function (){
            this.clearGrades();
        },
        clearGrades: function (){
            this.grades = [];
        },
        getAllGradesByCompanyId: async function (companyId:any) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/Grade/GetGradeListByCompanyId';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, {"companyId": Number(companyId)}, null, "getClubList" + new Date().getMilliseconds() + "_")
            if((Result?.length??-1)>0){
                this.grades = [];
                for(const item of Result){
                    this.grades.push(item as Grade);
                }
                return Result;
            }
        },
        // getAllGrades(){
        //
        // }
    },
    getters: {
        getAllGrades: state => state.grades,
        getGradeNameByGradeId: state=>function (gradeId:number){
            return state.grades.find((grade:any)=>grade.id==gradeId)?.GradeName??'';
        }
    },
})